import React from "react"
import Solutii from "../../components/Solutii/Solutii"
import Layout from "../../markup/layout/layout"
import { PageBanner } from "../../markup/layout/PageBanner"
import Seo from "../../markup/layout/seo"

// const solutionInfo = {
//   Name: "Automatizari",
//   Description:
//     "WEBICC este o platforma software SCADA dezvoltata pe tehnologii web pentru vizualizarea proceselor industriale atat in varianta management local cat si de la distanta. Aceasta platforma este imbunatatita constant pentru a se adapta permanent la cerintele pietii de software industrial. ",
//   Category: "Automatizari",
//   ShortDescription: "WEBICC este solutia perfecta pentru conectarea dispozitivelor industriale inteligente in aplicatii industriale precum automatizarea, monitorizarea de la distanta si intretinerea predictiva. Se poate integra cu succes in aplicatiile pentru sistemele de energie solara si eoliana, aplicatiile cu senzori in agricultura, aplicatii cu irigatii, monitorizarea mediului, in industria grea unde sunt controlate benzi transportoare pe distante lungi, in industria alimentara si multe altele",
//   Gallery: {
//     data: [
//       {
//         attributes: {
//           url: require("../../images/gallery/shutterstock_1936177924.png")
//             .default,
//         },
//       },
//       {
//         attributes: {
//           url: require("../../images/gallery/shutterstock_1504979759.png")
//             .default,
//         },
//       },
//     ],
//   },
// }

function solutii({ slug }) {
  const pageTitle = "Solutii"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        isHomePage={false}
        pageInfo={{ title: pageTitle, parent: { title: "", slug: "" }, hasParent: false }}
      />
      <Solutii solutionSlug={slug} />
    </Layout>
  )
}

export default solutii
